import React from "react";
import { Link } from "gatsby";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/hand.svg";
import { StaticImage } from "gatsby-plugin-image";
import ImageBlock from "components/services/ImageBlock";
import PaymentsGrid from "components/services/PaymentsGrid";
import PaymentsReasons from "components/services/PaymentsReasons";

export default () => (
  <>
    <Meta url="/payments/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="Payments Infrastructure"
      black="For China"
      text="Use AppInChina Pay to accept payments in Mainland China, and manage your China-side business online from anywhere in the world."
    />
    <ImageBlock
      title="Fully Integrated, Unified Platform"
      text={
        <>
          <p>
            Our platform brings together everything that’s required to deploy
            and manage your apps, games, and online services in China.
          </p>
          <p>
            AppInChina Pay powers your mobile payments,{" "}
            <Link to="/services/monetization/in-app-purchases/">
              in-app purchases
            </Link>
            , in-game payments, online retail and SaaS platforms using our
            integrated system.
          </p>
        </>
      }
      img={
        <StaticImage
          src="../images/photos/online-shopping.jpg"
          alt="Alipay"
          placeholder="blurred"
          layout="fixed"
          width={800}
          height={650}
        />
      }
    />
    <PaymentsGrid />
    <PaymentsReasons />
    <CTA />
    <Footer />
  </>
);
