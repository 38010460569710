import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import Card from "components/shared/Card";
import smartphone from "images/svg_icons/smartphone.svg";
import table from "images/svg_icons/table.svg";
import coding from "images/svg_icons/coding.svg";
import { Link } from "gatsby";

export default () => (
  <div className={classes.container}>
    <Layout>
      <h3>How We Can Help You</h3>
      <div className={classes.cards}>
        <Card
          title="Consolidated Financial Management"
          align="verticalreverse"
          content={
            <>
              <p>
                Unlike WeChat Pay and Alipay's standard backends, AppInChina Pay
                brings it all together in one consolidated English-language
                interface.
              </p>
              <p>
                Export your data to your own financial software. Track all
                transactions through our robust analytics and metrics tools.
              </p>
            </>
          }
          img={table}
        />
        <Card
          title="Designed for Developers"
          align="verticalreverse"
          content={
            <>
              <p>
                Our SDKs and APIs are easy-to-use so your developers don’t need
                to spend months stitching together disparate systems for Alipay
                and WeChat Pay, or integrating their functionality. Read the
                Docs
              </p>
              <a
                target="_blank"
                href="https://documentation.appinchina.co/"
                rel="noopener noreferrer"
              >
                Read the Docs
              </a>
            </>
          }
          img={coding}
        />
        <Card
          title="A Mobile-First Approach To Payments"
          align="verticalreverse"
          content={
            <p>
              WeChat Pay and Alipay are kings of{" "}
              <Link to="/services/monetization/in-app-purchases/">
                mobile payment
              </Link>{" "}
              in Mainland China. AppInChina Pay opens these Chinese payment
              systems up to you, seamlessly bridging the gap between currencies
              and your current financial management and tracking systems.
            </p>
          }
          img={smartphone}
        />
      </div>
    </Layout>
  </div>
);
