import React from "react";
import * as classes from "./index.module.less";

export default () => (
  <div className={classes.container}>
    <h3 className={classes.center}>
      AppInChina Pay: Trusted payments platform for China
    </h3>
    <div className={classes.grid}>
      <div>
        <h4>
          <span>1</span>Ongoing Improvements
        </h4>
        <p>
          We listen to our users to release new features and improvements as
          needed to ensure you maintain compliance, compatibility, and
          usability, according to your needs.
        </p>
      </div>
      <div>
        <h4>
          <span>2</span>Reliable
        </h4>
        <p>
          Our systems operate with 99.9%+ uptime and are highly scalable and
          redundant. AppInChina Pay is certified to the highest compliance
          standards.
        </p>
      </div>
      <div>
        <h4>
          <span>3</span>Full English Support
        </h4>
        <p>
          When you need help, our international, English- speaking support team
          is just a phone call or message away.
        </p>
      </div>
      <div>
        <h4>
          <span>4</span>One-Click Data Export
        </h4>
        <p>
          Import all data into your current financial software packages. Or use
          our APIs to fully integrate the live data into your system.
        </p>
      </div>
    </div>
  </div>
);
